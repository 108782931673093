.grecaptcha-badge {
    z-index: 10;
}

/*
**====================================================================================
**			TOP PANEL
**====================================================================================
**/
.toppanel__row {
    display: grid;
    grid-template-columns: 70% 30%;
    padding: 15px 0px;

    .toppanel__col {
        display: flex;
        align-items: center;
        position: relative;
        opacity: 1;
        transition: all 0.7s ease;

        &:first-child {
            left: 0px;
        }

        &:last-child {
            justify-content: flex-end;
            right: 0px;
        }

        .toppanel__contact-list {
            li {
                display: flex;
                align-items: center;
            }

            a {
                font-weight: 600;
                color: $color2;

                &:hover,
                &:focus {
                    color: $color1;
                }
            }

            i.nc-icon-outline {
                font-size: 29px;
                margin-right: 14px;
                color: $color1;
                position: relative;
                top: 1px;
            }
        }

        .skry-btn {
            background: $color2;

            &:hover,
            &:focus {
                background: $color1;
                color: #fff;
            }
        }
    }

    &.toppanel__row--hide {
        .toppanel__col {
            &:first-child {
                left: 50%;
                opacity: 0;
            }

            &:last-child {
                right: 70%;
                opacity: 0;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .toppanel__row {
        margin: 0px 70px;
    }
}

@media only screen and (max-width: 899px) {
    .toppanel__row {
        grid-template-columns: 60% 40%;
    }
}

@media only screen and (max-width: 578px) {
    .toppanel__row {
        display: none;
    }
}