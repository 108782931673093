/*
**====================================================================================
**			NGG - templatka
**====================================================================================
**/
.skry-gal__wrapper {
    text-align: center;
    font-size: 0px;
    margin: 0px -5px;
    overflow: visible;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .skry-gal__item {
        position: relative;
        margin: 0px 5px 10px;
        width: calc(25% - 10px);

    }
    .skry-gal__thumbnail {
        position: relative;
        transform: translateX(0);

        img {
            transform: translateX(0px);
            transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
        }

        a {
            display: block;
        }
    }
    .skry-gal__hover {
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        transform: translateX(0);

        .skry-gal__hover-over {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0;
            background: $color1;
            transition: opacity 0.3s cubic-bezier(0.25, 1, 0.5, 1);
        }

        .skry-gal__search-magn {
            position: relative;
            left: 0px;
            top: 0px;
            width: 15px;
            height: 15px;

            &::before, &::after {
                content: "";
                position: absolute;
                transition: all .55s ease;
                opacity: 0;
                visibility: hidden;
                will-change: transform, opacity;
            }
            &::before {
                width: 100%;
                height: 100%;
                left: 0px;
                border: 3px solid $color1;
                border-radius: 100px;
                transform: translate(-80px, -80px);
            }
            &::after {
                width: 6px;
                height: 3px;
                background: $color1;
                transform: rotate(45deg);
                right: -2px;
                bottom: -1px;
                transform: translate(80px, 80px) rotate(45deg);
            }
        }

        &:hover {
            .skry-gal__hover-over {
                opacity: 0.7;
            }
        }
    }

    &--th-left-triangle {
        .skry-gal__thumbnail {
            overflow: hidden;

            &::before {
                content: "";
                position: absolute;
                top: 0px;
                right: 8px;
                bottom: 0px;
                left: 0px;
                background: #fff;
                transform: translateX(0);
            }

            a {
                &:hover, &:focus {
                    img {
                        transform: translateX(20px);
                    }
                }
            }
        }
        .skry-gal__hover {
            .skry-gal__hover-left-triangle {
                position: absolute;
                top: 0px;
                right: auto;
                bottom: 0px;
                left: 0px;
                width: 75px;
                background: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                clip-path: polygon(37% 100%, 0% 100%, 0% 0%, 37% 0%, 100% 50%);
                transform: translateX(-75px);
                transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
            }

            &:hover {
                .skry-gal__hover-left-triangle {
                    transform: translateX(-8px);

                    .skry-gal__search-magn {
                        &::before, &::after {
                            opacity: 1;
                            visibility: visible;
                            transform: translate(0px, 0px);
                        }
                        &::after {
                            transform: translate(0px, 0px) rotate(45deg);
                        }
                    }
                }
            }
        }
    }
    &--th-border {
        .skry-gal__hover {
            .skry-gal__hover-over {
                transition: all 0.5s ease-in;
            }
            .skry-gal__hover-border {
                position: absolute;
                top: 3px;
                right: 3px;
                bottom: 3px;
                left: 3px;

                &::before, &::after {
                    content: "";
                    background: none;
                    position: absolute;
                }
                &::after {
                    bottom: 0px;
                    top: 0px;
                    left: 0px;
                    right: auto;
                    width: 0%;
                    height: auto;
                    margin: 0px;
                    border-top: 1px solid #fff;
                    border-bottom: 1px solid #fff;
                    transition: all 0.6s ease-in;
                }

                &::before {
                    bottom: 0px;
                    top: auto;
                    left: 0px;
                    right: 0px;
                    width: auto;
                    height: 0;
                    margin: 0px;
                    border-left: 1px solid #fff;
                    border-right: 1px solid #fff;
                    transform: none;
                    transition: all 0.7s ease-in;
                }
            }
            &:hover, &:focus {
                .skry-gal__hover-border {
                    &::before, &::after {
                        opacity: 0.1;
                    }
                    &::after {
                        width: 100%;
                    }

                    &::before {
                        height: 100%;
                        top: auto;
                    }
                }
            }
        }
    }
    &--th-magn-center {
        .skry-gal__hover {
            display: flex;
            justify-content: center;
            align-items: center;

            .skry-gal__search-magn {
                &--center {
                    width: 20px;
                    height: 20px;
                    left: -9px;
                    &::before {
                        border: 3px solid #fff;
                    }
                    &::after {
                        width: 9px;
                        right: -4px;
                        background: #fff;
                    }
                }
            }
            &:hover, &:focus {
                .skry-gal__search-magn {
                    &--center {
                        &::before, &::after {
                            opacity: 1;
                            visibility: visible;
                            transform: translate(0px, 0px);
                        }
                        &::after {
                            transform: translate(0px, 0px) rotate(45deg);
                        }
                    }
                }
            }
        }
    }
}

/*RWD*/
@media only screen and (max-width: 991px) {
    .skry-gal__wrapper .skry-gal__item {
        width: calc(33.33% - 10px);
    }
}
@media only screen and (max-width: 480px) {
    .skry-gal__wrapper .skry-gal__item {
        width: calc(50% - 10px);
    }
}