/*
**====================================================================================
**			SKRY ANIM
**====================================================================================
**/
.skry-anim {
    will-change: transform, opacity;

    &.skry-anim {
        &--slideright {
            transform: translateX(200px);
            transition-property: all;
            transition-duration: 1.5s;
            transition-delay: 0s;
            transition-timing-function: fade;
            opacity: 0;

            &.skry-show {
                transform: translateX(0px);
                opacity: 1;
            }
        }
        &--slideleft {
            transform: translateX(-200px);
            transition-property: all;
            transition-duration: 1.5s;
            transition-delay: 0s;
            transition-timing-function: fade;
            opacity: 0;

            &.skry-show {
                transform: translateX(0px);
                opacity: 1;
            }
        }
        &--slidebot {
            transform: translateY(200px);
            transition-property: all;
            transition-duration: 1.5s;
            transition-delay: 0s;
            transition-timing-function: fade;
            opacity: 0;

            &.logotype__item {
                &:nth-child(6n+2) { transition-delay: 0.1s; }
                &:nth-child(6n+3) { transition-delay: 0.2s; }
                &:nth-child(6n+4) { transition-delay: 0.3s; }
                &:nth-child(6n+5) { transition-delay: 0.4s; }
                &:nth-child(6n+6) { transition-delay: 0.5s; }
                &:nth-child(6n+7) { transition-delay: 0.6s; }
            }

            &.skry-show {
                transform: translateY(0px);
                opacity: 1;
            }
        }
        &--fade {
            opacity: 0;
            transition-property: all;
            transition-duration: 0.9s;
            transition-delay: 0s;
            transition-timing-function: cubic-bezier(.47, 0, .74, .71);

            &.skry-show {
                opacity: 1;
            }
        }
        &--header {
            &::before {
                transform: translate(-100px, 0px);
                opacity: 0;
                transition: transform 1.5s cubic-bezier(.17,.84,.44,1), opacity 0.5s cubic-bezier(.17,.84,.44,1);
            }
            &::after {
                transform: translate(0px, -70px);
                opacity: 0;
                transition: transform 1.5s cubic-bezier(.17,.84,.44,1), opacity 0.5s cubic-bezier(.17,.84,.44,1);
            }
            &.skry-show {
                &::before {
                    transform: translate(0px, 0px);
                    opacity: 1;
                }
                &::after {
                    transform: translate(0px, 0px);
                    opacity: 1;
                }
            }
        }
        &--t3d {
            transform: translate3d(0px, 110px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 7deg);
            opacity: 0;
            transform-style: preserve-3d;
            transition: all 0.8s ease-in-out;

            &.skry-show {
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
                opacity: 1;
            }
        }
    }
    .skry-element:not(.skry-element-load) {
        display: none;
    }
}