/*
**====================================================================================
**			HEADER PODSTRONY
**====================================================================================
**/
body {

    &.tm_pb_builder #tm_builder_outer_content .tm_pb_section {
        &.skry-header {
            padding: 55px 0px 30px;

            .skry-header__title {
                font-size: 70px;
                text-align: center;
                text-transform: none;
                line-height: normal;
                letter-spacing: 0px;
                margin: 0px;
                position: relative;
                -webkit-hyphens: none;
                -ms-hyphens: none;
                hyphens: none;

                &::before, &::after {
                    content: "";
                    position: absolute;
                    right: 0px;
                    bottom: 0px;
                    left: 0px;
                    margin: 0px auto;

                }
                &::before {
                    background: #000;
                    width: 28px;
                    height: 2px;
                    bottom: -15px;
                }

                &::after {
                    width: 20px;
                    border-top: 15px solid #f19413;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 0px solid transparent;
                    bottom: -31px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    body.tm_pb_builder #tm_builder_outer_content .tm_pb_section.skry-header .skry-header__title {
        font-size: 35px;
    }
}

@media only screen and (max-width: 768px) {
    body.tm_pb_builder #tm_builder_outer_content .tm_pb_section.skry-header .skry-header__title {
        font-size: 32px;
        text-align: center;
        line-height: normal;
    }

    body.tm_pb_builder #tm_builder_outer_content .tm_pb_section.skry-header {
        padding: 40px 0px 40px;
    }
}

@media only screen and (max-width: 480px) {
    body.tm_pb_builder #tm_builder_outer_content .tm_pb_section.skry-header {
        padding: 35px 0px 35px;

        .skry-header__title {
            &::before {
                width: 20px;
            }
            &::after {
                width: 12px;
                border-top: 11px solid #f19413;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                bottom: -27px;
            }
        }
    }

    body.tm_pb_builder #tm_builder_outer_content .tm_pb_section.skry-header .skry-header__title {
        font-size: 23px;
    }
}

@media only screen and (max-width: 360px) {
}

@media only screen and (max-width: 320px) {
    body.tm_pb_builder #tm_builder_outer_content .tm_pb_section.skry-header .skry-header__title {
        font-size: 19px;
    }
}



/*
**====================================================================================
**			NAGŁÓWKI
**====================================================================================
**/
/* Nagłówki ogólnie */
.skry-content {
    *[class*="skry-t-"] {
        font-style: normal;
        margin: 0px;
        padding: 0px;
        font-weight: 500;
        letter-spacing: 0px;
        -webkit-hyphens: none;
        -ms-hyphens: none;
        hyphens: none;
    }

    .skry-t--center {
        text-align: center;
    }

    .skry-t--left {
        text-align: left;
    }

    .skry-t--right {
        text-align: right;
    }

    .skry-t-1 {
        text-align: center;
        margin-bottom: 30px;
    }

    .skry-t-2 {
        font-size: 63px;
        margin-bottom: 55px;
        text-align: center;
        line-height: 58px;
        font-weight: 400;
        letter-spacing: 0px;
        text-transform: none;
        position: relative;

        &.skry-t--left {
            text-align: left;

            &::before {
                right: auto;
            }
        }

        &.skry-t--right {
            text-align: right;

            &::before {
                left: auto;
            }
        }
    }

    .skry-t-3 {
        font-size: 27px;
        font-weight: 600;
        text-transform: none;
        letter-spacing: 0px;
        position: relative;
        padding: 0px 0px 15px 28px;
        margin-bottom: 5px;
        -webkit-hyphens: none;
        -ms-hyphens: none;
        hyphens: none;

        &::before {
            content: "";
            position: absolute;
            top: 6px;
            left: 0px;
            display: block;
            width: 18px;
            height: 18px;
            border: 3px solid $color1;
            background: #191919;
            border-radius: 50px;
        }

        &.skry-t--center {
            text-align: center;
            font-size: 20px;
            padding-left: 0px;
            padding-top: 28px;

            &::before {
                right: 0px;
                margin: 0px auto;
            }
        }
    }
}

/* Nagłówki różne ustawienia */
#tm_builder_outer_content {
    .skry-content {
        .skry-t-3+ul {
            margin-top: 25px;
        }

        p+.skry-t-3 {
            margin-top: 40px;
        }

        .skry-ele-img+.skry-t-3 {
            margin-top: 60px;
        }

        p+h2.skry-t-2 {
            margin-top: 40px;
        }

        *[class*="skry-t-"]+p {
            margin-top: 0px;
        }
    }
}

/* Nagłówki RWD */
@media only screen and (max-width: 1200px) {
    .skry-content {
        .skry-t-1 {
            font-size: 35px;
        }

        .skry-t-2 {
            font-size: 47px;
        }
    }
}

@media only screen and (max-width: 800px) {
    .skry-content .skry-t-2 {
        font-size: 38px;
        margin-bottom: 18px;
        line-height: normal;
    }
}

@media only screen and (max-width: 768px) {
    .skry-content {
        .skry-t-1 {
            font-size: 31px;
        }

        .skry-t-2 {
            font-size: 28px;
        }

        .skry-t-3 {
            text-align: center;
            font-size: 20px;
            padding-left: 0px;
            padding-top: 0px;

            &::before {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .skry-content {
        .skry-t-2 {
            font-size: 23px;
        }

        .skry-t-3 {
            font-size: 17px;
            padding-bottom: 11px;
            font-weight: 600;
        }
    }
}

@media only screen and (max-width: 320px) {
    .skry-content .skry-t-3 {
        font-size: 16px;
    }
}