/*
**====================================================================================
**			404
**====================================================================================
**/
.error404 {
    .site-main {
        margin-bottom: 0;
    }
    .site-content_wrap {
        margin-top: 35px;
        margin-bottom: 35px;
    }
}

.skry-404__section {
    text-align: center;
    font-size: 0px;
    margin-bottom: 45px;
    margin-top: 0px;

    .skry-404__row {
        display: block;
        width: 100%;
        box-sizing: border-box;
        margin-top: 40px;

        &.skry-404__row--title {
            margin-top: 0px;
        }

        &.skry-404__row--content {
            padding: 0px 0px 0px 0px;
            margin-top: -15px;
        }

        br {
            display: none;
        }

        h1 {
            color: #2c2b2a;
            font-size: 161px;
            line-height: normal;
            font-weight: 500;
            text-align: center;
            padding: 0px 00px 0px 0px;
            margin: 0px;
            position: relative;
            top: 0px;
        }

        h2 {
            color: $color1;
            text-align: center;
            font-size: 37px;
            line-height: normal;
            font-weight: 500;
            text-transform: none;
            padding: 15px 0px 25px 0px;
            margin: 0px;
        }

        p {
            font-size: 16px;
            text-align: center;
            padding: 0px;
            margin: 0px;
            -webkit-hyphens: none;
            -ms-hyphens: none;
            hyphens: none;
        }

        i {
            &::before {
                font-size: 18px;
            }
        }

        .skry-btn {
            margin: 0px 5px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .skry-404__section {
        margin-bottom: 10px;

        .skry-404__row {
            h1 {
                font-size: 125px;
                margin-top: -20px;
            }

            h2 {
                font-size: 32px;
                padding-top: 25px;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .skry-404__section {

        .skry-404__row {
            margin-top: 18px;

            h1 {
                font-size: 79px;
            }

            h2 {
                font-size: 19px;
                padding-top: 20px;
                padding-bottom: 15px;
            }

            p {
                font-size: 13px;
            }

            br {
                display: block;
            }

            .skry-btn:first-of-type {
                margin-bottom: 5px;
            }
        }
    }
}

@media only screen and (max-width: 320px) {
    .skry-404__section .skry-404__row i::before {
        position: relative;
        top: 2px;
    }
}