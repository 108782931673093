/*
**====================================================================================
**			Kafelki 3D
**====================================================================================
**/
.tile3d__con {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    justify-content: center;

    .tile3d__item {
        flex: 0 1 152px;
        width: 152px;
        height: 152px;
        margin: 5px;
        perspective: 3000px;
        transform-style: preserve-3d;

        .tile3d__item-wrapper {
            transition: all 0.8s ease;

            .tile3d__item-grid {
                transform-style: preserve-3d;
                perspective: 1300px;
                position: relative;

                .tile3d__front {
                    transform: rotateY(0deg);

                    &::before {
                        background: $color1;
                        opacity: 1;
                    }
                }

                .tile3d__back {
                    transform: rotateY(180deg);
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;

                    &::before {
                        opacity: 0;
                        background: $color1;
                    }
                }

                .tile3d__front,
                .tile3d__back {
                    backface-visibility: hidden;
                    -webkit-backface-visibility: hidden;
                    transform-style: preserve-3d;
                    transition: all 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);

                    &::before,
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        bottom: 0px;
                        left: 0px;
                        z-index: 2;
                        transition: all 0.7s ease;
                    }
                }

                .tile3d__content {
                    color: #fff;
                    line-height: 23px;
                    font-size: 17px;
                    transform: translateZ(60px);
                    padding: 15px 10px;
                    width: 100%;
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    flex-direction: column;
                    transition: all 0.4s ease-out;

                    &>* {
                        -ms-grid-row-align: center;
                        align-self: center;
                    }

                    h3 {
                        display: flex;
                        flex-direction: column;
                        transition: all 0.3s ease;

                        span {
                            display: block;
                            font-size: 19px;
                            padding-top: 5px;
                        }
                    }

                    h3,
                    a {
                        color: #fff;
                        font-size: 15px;
                        letter-spacing: 1px;
                        font-weight: 500;
                        text-align: center;
                        text-transform: none;
                        -webkit-hyphens: none;
                        -ms-hyphens: none;
                        hyphens: none;
                    }

                    a {
                        &:hover {
                            color: #333333;
                        }
                    }
                }

                .tile3d__figure {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;

                    img {
                        height: 100% !important;
                        width: auto !important;
                        max-width: none !important;
                    }
                }

                .tile3d__permalink {
                    display: inline-block;
                    position: relative;
                    font-size: 16px;

                    &::after {
                        content: "\f178";
                        font: normal normal normal 18px/1 FontAwesome;
                        color: white;
                        border: 2px solid white;
                        border-radius: 50%;
                        padding: 9px;
                        transition: all .3s ease-out;
                    }

                    &:hover::after {
                        border-color: transparent;
                        padding: 12px;
                        background: rgba(255, 255, 255, 0.9);
                        color: $color1;
                    }

                    span {
                        color: #fff;
                        display: none;
                    }
                }

                &:hover .tile3d__content h3,
                &:hover .tile3d__content h3 a {
                    color: #fff;
                }
            }

            &:hover .tile3d__item-grid {
                .tile3d__front {
                    transform: rotateY(-180deg);

                    &::before {
                        background-color: $color1;
                        opacity: 0.2;
                    }
                }

                .tile3d__back {
                    transform: rotateY(0deg);

                    &::before {
                        opacity: 0.9;
                    }
                }
            }
        }

        .tile3d__item-wrapper,
        .tile3d__item-grid,
        .tile3d__item-grid .tile3d__front,
        .tile3d__item-grid .tile3d__back {
            height: 100%;
        }
    }

    &.tile3d__con--home {
        justify-content: flex-start;
    }

    &.tile3d__con--offer {
        .tile3d__item {
            flex: 0 1 352px;
            width: 352px;
            height: 252px;
        }

        .tile3d__item-grid .tile3d__content h3,
        .tile3d__item-grid .tile3d__content h3 a {
            font-size: 18px;
        }
    }
}

@media (max-width: 1200px) {
    .tile3d__con .tile3d-item {
        flex: 0 1 50%;
        width: 50%;
        min-width: 171px;
    }
}

@media (max-width: 480px) {
    .tile3d__con {
        .tile3d__item {
            flex: 0 1 100%;
            width: 100%;

            .tile3d__item-wrapper {
                .tile3d__item-grid {
                    .tile3d__front {
                        transform: rotateY(-180deg);

                        &::before {
                            opacity: 0;
                        }
                    }

                    .tile3d__back {
                        transform: rotateY(0deg);

                        &::before {
                            opacity: 0.9;
                            background: rgb(51, 51, 51);
                        }
                    }

                    &:hover .tile3d__content {

                        h3,
                        a {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}



/*
**====================================================================================
**			GRID z przesuwnym tłem
**====================================================================================
**/
.skry-grid__wrapper--sliding {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0px 0px -30px 0px;
    position: relative;

    & + .skry-grid__wrapper--sliding {
        margin-top: 30px;
    }

    .skry-grid__item--img {
        background: transparent;
        max-width: 120px;
        height: auto;
    }

    .skry-grid__item-title {
        font-family: Garamond, serif;
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 31px;
        letter-spacing: 0em;
        position: relative;
        text-align: center;
        color: #000000;
        display: block;
        padding-top: 8px;
        transition: all 0.9s ease;
    }

    .skry-grid__item {
        width: 30%;
        height: auto;
        margin: 0px 15px 30px;
        position: relative;
        transition: all 0.9s ease;

        &:nth-child(2n + 1) {
            transition-delay: 0.2s;
        }
        &:nth-child(2n + 2) {
            transition-delay: 0.4s;
        }

        .skry-grid__item-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-top: 4px solid #d2d2d2;
            border-left: 4px solid #d2d2d2;
            border-right: 4px solid #d2d2d2;
            border-bottom: 4px solid $color1;
            height: 302px;
            position: relative;
            padding: 0px;
            margin-bottom: 15px;
            transition: all 0.9s ease;

            &::before {
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                background: rgb(223, 223, 223);
                z-index: 1;
                opacity: 0;
                transition: opacity 0.9s ease;
                will-change: opacity;
            }

            img {
                max-height: 100%;
            }

            .skry-grid__item-icon {
                position: absolute;
                bottom: -27px;
                left: 0;
                right: 0px;
                width: 33px;
                height: 42px;
                margin: 0px auto;
                background: #fff;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
                z-index: 3;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0px;
                    left: 0;
                    right: 0px;
                    background: transparent url("../images/chefsplace-tile-icon.png") no-repeat center center/contain;
                    width: 31px;
                    height: 39px;
                    z-index: 1;
                    margin: 2px auto;
                }
            }
        }

        &:hover,
        &:focus {
            .skry-grid__item-title {
                color: $color1;
            }

            .skry-grid__item-inner {
                border-color: $color1;

                &::before {
                    opacity: 0.3;
                }

                .skry-grid__item-icon {
                    -webkit-animation: tileIconMove 1.5s ease infinite;
                            animation: tileIconMove 1.5s ease infinite;
                }
            }
        }
    }

    &.skry-grid__wrapper--small {
        .skry-grid__item {
            width: 250px;

            .skry-grid__item-inner {
                height: 200px;
            }
        }
    }

    .skry-grid__e-hover {
        position: absolute;
        left: -100px;
        top: 50px;
        border: 4px solid $color1;
        width: 400px;
        height: 300px;
        z-index: -1;
        opacity: 0;
        transition: all 0.9s ease;
    }

    .skry-grid--e-hover-anim {
        -webkit-animation: skry-grid-slide-anim 0.5s ease-in-out both;
        animation: skry-grid-slide-anim 0.5s ease-in-out both;
    }
}
.home .skry-grid__wrapper--sliding .skry-grid__item .skry-grid__item-inner .skry-grid__item-icon {
	background: #f9f9f9;
}

@-webkit-keyframes tileIconMove {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(3px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes tileIconMove {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(3px);
    }
    100% {
        transform: translateY(0px);
    }
}


/*RWD*/

@media only screen and (max-width: 1410px) {
    .skry-grid__wrapper--sliding .skry-grid__item {
        width: 406px;
    }
}

@media only screen and (max-width: 991px) {
    .skry-grid__wrapper--sliding .skry-grid__item {
        width: 285px;
        margin: 0px 7px 27px;

        .skry-grid__item-inner {
            height: 213px;
        }
    }
}

@media only screen and (max-width: 800px) {
    .skry-grid__wrapper--sliding .skry-grid__item-title {
        font-size: 20px;
        line-height: 24px;
    }
}
@media only screen and (max-width: 768px) {
    .skry-grid__wrapper--sliding .skry-grid__item {
        margin: 0px 0px 25px;
    }
    .skry-grid__wrapper--sliding.skry-grid__wrapper--small .skry-grid__item {
        margin-left: 7px;
        margin-right: 7px;
    }
}

@media only screen and (max-width: 480px) {

    .skry-grid__wrapper--sliding .skry-grid-item__title {
        font-size: 19px;
    }
}

@media only screen and (max-width: 375px) {
    .skry-grid__wrapper--sliding .skry-grid__item:hover .skry-grid-item__inner {
        background: $color1;
    }
}

@-webkit-keyframes skry-grid-slide-anim {
    0% {
        transform: rotate(0) scale(1);
    }

    50% {
        transform: scale(0.3);
    }

    100% {
        transform: rotate(180deg) scale(1);
    }
}

@keyframes skry-grid-slide-anim {
    0% {
        transform: rotate(0) scale(1);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: rotate(0deg) scale(1);
    }
}



/*
**====================================================================================
**			GRID z kwadracikami a'la ebitubi
**====================================================================================
**/
.skry-grid__wrapper--squ {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    .skry-grid__item {
        background: transparent;
        padding: 0px;
        min-height: 135px;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        &:hover {
            z-index: 4;
        }

        h4 {
            color: #fff;
            text-align: center;
            text-transform: none;
            font-size: 20px;
            position: relative;
            top: 0px;
            opacity: 1;
            transition: all 0.3s ease;
        }

        .skry-grid__item-hoverback {
            background: #fff;
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0px;
            top: 0px;
            box-shadow: 0px 0px 5px #d0d0d0;
            transition: all 0.6s ease;
            z-index: -2;

            span {
                position: absolute;
                background: $color1;
                z-index: -1;
                width: 34%;
                height: 51%;
                transition: all 0.6s ease;

                &.skry-grid__item-hb-t-1,
                &.skry-grid__item-hb-t-2,
                &.skry-grid__item-hb-t-3 {
                    right: 0px;
                    top: 50%;
                }

                &.skry-grid__item-hb-t-2 {
                    right: 33%;
                    transition: all 0.6s ease 0.2s;
                }

                &.skry-grid__item-hb-t-3 {
                    right: 66%;
                    transition: all 0.6s ease 0.4s;
                }

                &.skry-grid__item-hb-t-4,
                &.skry-grid__item-hb-t-5,
                &.skry-grid__item-hb-t-6 {
                    left: 0px;
                    bottom: 50%;
                }

                &.skry-grid__item-hb-t-5 {
                    left: 33%;
                    transition: all 0.6s ease 0.2s;
                }

                &.skry-grid__item-hb-t-6 {
                    left: 66%;
                    transition: all 0.6s ease 0.4s;
                }
            }
        }

        .skry-grid__item-hoverbox {
            opacity: 0;
            position: absolute;
            top: -35px;
            bottom: -35px;
            left: -35px;
            right: -35px;
            background: transparent;
            padding: 27px;
            z-index: 3;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            flex-direction: column;
            transition: all 0.7s ease;

            p {
                color: $color1;
                line-height: 19px;
                text-align: center !important;
                font-size: 16px;
            }

            h4 {
                color: $color1;
            }

            .skry-btn {
                position: absolute;
                bottom: 80px;
                right: 80px;
                padding: 7px 15px;
                opacity: 0;
                transition: all 0.7s ease;
            }
        }

        &:hover {
            &>h4 {
                top: -70px;
                opacity: 0;
            }

            .skry-grid__item-hoverbox {
                opacity: 1;
                transform: rotate(0deg);
            }

            .skry-btn {
                bottom: 1px;
                right: 1px;
                opacity: 1;
            }

            .skry-grid__item-hoverback {
                top: -35px;
                right: -35px;
                bottom: -35px;
                left: -35px;

                span {
                    width: 12px;
                    height: 12px;

                    &.skry-grid__item-hb-t-1 {
                        right: 2px;
                        top: 2px;
                    }

                    &.skry-grid__item-hb-t-2 {
                        right: 16px;
                        top: 2px;
                    }

                    &.skry-grid__item-hb-t-3 {
                        right: 2px;
                        top: 16px;
                    }

                    &.skry-grid__item-hb-t-4 {
                        left: 2px;
                        bottom: 2px;
                    }

                    &.skry-grid__item-hb-t-5 {
                        left: 16px;
                        bottom: 2px;
                    }

                    &.skry-grid__item-hb-t-6 {
                        left: 2px;
                        bottom: 16px;
                    }
                }
            }
        }
    }
}



/*
**====================================================================================
**			GRID z przesuwnymi kafelkami
**====================================================================================
**/
.skry-grid__wrapper--fourt {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 0px -30px 0px;
    position: relative;

    .skry-grid__item-img {
        background: transparent;
        border-radius: 100px;
        max-width: 120px;
        height: auto;
        /*gdy zdjecie ma być tłem*/
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        z-index: -1;
    }

    .skry-grid__item-title {
        position: relative;
        text-align: center;
        font-size: 23px;
        font-weight: normal;
        color: $color2;
        background: transparent;
        padding: 0px 8px;
        position: relative;
        z-index: 1;
        transition: all 0.9s ease;

        &::before {
            content: "";
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: -7px;
            right: -7px;
            background: #fff;
            z-index: -1;
            transform: skewX(-10deg);
            transition: opacity 0.9s ease;
            will-change: opacity;
        }
    }

    .skry-grid__item {
        width: 30%;
        height: auto;
        margin: 0px 15px 30px;
        position: relative;
        transition: all 0.9s ease;

        .skry-grid__item-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 0px solid $color1;
            background: transparent;
            border-radius: 0px;
            height: 245px;
            position: relative;
            padding: 20px;
            overflow: hidden;
            transition: all 0.9s ease;

            &::after {
                content: "";
                background: #000;
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                z-index: -1;
                opacity: 0.3;
            }

            &:hover,
            &:focus {

                .skry-grid__item-title {
                    color: #fff;

                    &::before {
                        opacity: 0;
                    }
                }
            }
        }
    }

    .skry-grid__e-hover {
        position: absolute;
        left: -100px;
        top: 50px;
        background: $color1;
        width: 400px;
        height: 300px;
        z-index: -1;
        border-radius: 0x;
        opacity: 0;
        transition: all 0.6s ease;

        &.skry-grid__e-hover-tl {
            transition-delay: 0.2s;
        }

        &.skry-grid__e-hover-bl {
            transition-delay: 0.4s;
        }

        &.skry-grid__e-hover-br {
            transition-delay: 0.3s;
        }
    }

    .skry-grid--e-hover-anim {
        -webkit-animation: skry-grid-slide-anim 0.5s ease-in-out both;
        animation: skry-grid-slide-anim 0.5s ease-in-out both;
    }
}

/*RWD*/

@media only screen and (max-width: 1199px) {
    .skry-grid__wrapper--fourt {
        .skry-grid__item-title {
            font-size: 19px;
        }

        .skry-grid__item {
            width: 29%;

            .skry-grid__item-inner {
                height: 185px;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .skry-grid__wrapper--fourt {
        margin-bottom: -14px;

        .skry-grid__item {
            width: 45%;
            margin: 0px 7px 14px;

            .skry-grid__item-inner {
                height: 152px;
            }
        }
    }

    .skry-grid__wrapper--fourt {

        .skry-grid__item .skry-grid__item-inner {
            height: 230px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .skry-grid__wrapper--fourt .skry-grid__item {
        .skry-grid__item-inner {
            height: 177px;
        }
    }
}

@media only screen and (max-width: 575px) {
    .skry-grid__wrapper--fourt {
        flex-direction: column;

        .skry-grid__item {
            width: 100%;
            margin: 0px auto 14px;
            max-width: 410px;

            .skry-grid__item-inner {
                height: 288px;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .skry-grid__wrapper--fourt .skry-grid__item {
        width: 100%;
        margin: 0px 0px 14px;
    }

    .skry-grid__wrapper--fourt .skry-grid-item__title {
        font-size: 19px;
    }

    .skry-grid__wrapper--fourt .skry-grid__item-img {
        max-width: none !important;
        width: auto;
    }
}

@media only screen and (max-width: 375px) {
    .skry-grid__wrapper--fourt .skry-grid__item:hover .skry-grid-item__inner {
        background: $color1;
    }
}