/*
**====================================================================================
**			BLOKI, CTA
**====================================================================================
**/
.skry-blo--mt {
    padding-top: 55px;
}

.skry-blo--mb {
    padding-bottom: 55px;
}

.skry-blo--mt-half {
    padding-top: 22px;
}

.skry-blo--mb-half {
    padding-bottom: 22px;
}

.tm_pb_builder #tm_builder_outer_content .tm_pb_section {
    &.hero-section {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url("../images/home-image.jpg") no-repeat center center / cover;

        .hero-image-title {
            text-align: center;
            img {
                max-height: 390px;
                width: auto;
                position: relative;
                top: 55px;
            }
        }
    }
    &.skry-blo--cta {
        background: $color1 url("../images/page-back-01.png") no-repeat center center / cover;
        padding: 55px 0px 55px;

        .cta__title {
            color: #fff;
            text-align: center;

            a {
                color: #4a4a4a;

                &:hover, &:focus {
                    color: #fff;
                }
            }

            &--1 {
                font-size: 39px;
                line-height: 40px;
            }
            &--2 {
                font-family: Roboto;
                font-weight: 300;
            }
        }
        .cta__hr {
            margin: 25px auto 13px;
            border-color: #fff;
        }
        .cta__hr, .cta__hr::after {
            border-color: #fff;
        }
    }

    &.skry-blo--light {
        background: #f9f9f9;
    }
    &.skry-blo-- {
        &section-1::before {
            background-image: url("../images/back-section-1.png");
        }
        &section-2::before {
            background-image: url("../images/back-section-2.png");
        }
        &section-3::before {
            background-image: url("../images/back-section-3.png");
        }
        &section-4::before {
            background-image: url("../images/back-section-4.png");
        }
        &section-5::before {
            background-image: url("../images/back-section-5.png");
        }
        &section-6::before {
            background-image: url("../images/back-section-6.png");
        }
        &section-home {
            min-height: 500px;
            display: flex;
            align-items: center;
            overflow: hidden;
            &::before {
                content: "";
                position: absolute;
                inset: 0px;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
        &section-1::before, &section-3::before, &section-5::before {
            background-position: left center;
        }
        &section-2::before, &section-4::before, &section-6::before {
            background-position: right center;
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
    .tm_pb_builder #tm_builder_outer_content .tm_pb_section {
        &.skry-blo-- {
            &section-1::before, &section-3::before, &section-5::before {
                left: -90px;
            }
            &section-2::before, &section-4::before, &section-6::before {
                right: -90px;
            }
        }
    }
}
@media only screen and (max-width: 1199px) {
    .tm_pb_builder #tm_builder_outer_content .tm_pb_section.skry-blo--section-home {
        min-height: 10px;
        &::before {
            opacity: 0.1;
        }
    }
}
@media only screen and (max-width: 991px) {
    .skry-blo--contact {
        .tm_pb_row {
            .tm_pb_column {
                -webkit-box-flex: 0;
                    -webkit-flex: 0 0 100%;
                    -ms-flex: 0 0 100%;
                    flex: 0 0 100%;
                    max-width: 100%;

                &:first-child {
                    margin-bottom: 55px;
                }
            }
        }
    }
}
@media only screen and (max-width: 800px) {
    .tm_pb_builder #tm_builder_outer_content .tm_pb_section.skry-blo--cta {
        .cta__title--1 {
            font-size: 27px;
            line-height: 35px;
        }
        .cta__title--2 {
            font-size: 17px;
            line-height: 25px;
        }
        .cta__hr {
            margin: 20px auto 20px;
        }
    }
    .tm_pb_builder #tm_builder_outer_content .tm_pb_section.skry-blo--section-home::before {
        opacity: 0.05;
    }
}

@media only screen and (max-width: 768px) {
    .skry-blo--mt {
        padding-top: 40px;
    }

    .skry-blo--mb {
        padding-bottom: 40px;
    }

    .skry-blo--mt-half {
        padding-top: 20px;
    }

    .skry-blo--mb-half {
        padding-bottom: 20px;
    }
    .tm_pb_builder #tm_builder_outer_content .tm_pb_section.skry-blo--section-home::before {
        background-size: cover;
    }
}

@media only screen and (max-width: 480px) {
    .skry-blo--mt {
        padding-top: 35px;
    }

    .skry-blo--mb {
        padding-bottom: 35px;
    }

    .skry-blo--mt-half {
        padding-top: 17px;
    }

    .skry-blo--mb-half {
        padding-bottom: 17px;
    }
    .tm_pb_builder #tm_builder_outer_content .tm_pb_section.skry-blo--cta {
        .cta__title--2 {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

@media only screen and (max-width: 375px) {
    .tm_pb_builder #tm_builder_outer_content .tm_pb_section.skry-blo--cta .cta__title--1 {
        font-size: 24px;
    }
}

@media only screen and (min-width: 1367px) {
    .xlg-hide {
        display: none !important;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1366px) {
    .lg-hide {
        display: none !important;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .md-hide {
        display: none !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .smd-hide {
        display: none !important;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .xmd-hide {
        display: none !important;
    }
}

@media only screen and (min-width: 376px) and (max-width: 480px) {
    .sm-hide {
        display: none !important;
    }
}

@media only screen and (min-width: 361px) and (max-width: 375px) {
    .xsm-hide {
        display: none !important;
    }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
    .sxsm-hide {
        display: none !important;
    }
}

@media only screen and (min-width: 321px) and (max-width: 360px) {
    .s-hide {
        display: none !important;
    }
}

@media only screen and (max-width: 320px) {
    .xs-hide {
        display: none !important;
    }
}

@media only screen and (min-width: 769px) {
    .xmd-top-hide {
        display: none !important;
    }
}

@media only screen and (max-width: 768px) {
    .xmd-bot-hide {
        display: none !important;
    }
}

@media only screen and (min-width: 481px) {
    .sm-top-hide {
        display: none !important;
    }
}

@media only screen and (max-width: 480px) {
    .sm-bot-hide {
        display: none !important;
    }
}

.content-center {
    text-align: center;
}

@media only screen and (min-width: 1367px) {
    .xlg-content-center {
        text-align: center;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1366px) {
    .lg-content-center {
        text-align: center;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .md-content-center {
        text-align: center;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .smd-content-center {
        text-align: center;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .xmd-content-center {
        text-align: center;
    }
}

@media only screen and (min-width: 376px) and (max-width: 480px) {
    .sm-content-center {
        text-align: center;
    }
}

@media only screen and (min-width: 361px) and (max-width: 375px) {
    .xsm-content-center {
        text-align: center;
    }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
    .sxsm-content-center {
        text-align: center;
    }
}

@media only screen and (min-width: 321px) and (max-width: 360px) {
    .s-content-center {
        text-align: center;
    }
}

@media only screen and (max-width: 320px) {
    .xs-content-center {
        text-align: center;
    }
}

@media only screen and (max-width: 1024px) {
    .smd-bot-content-center {
        text-align: center;
    }
}

@media only screen and (min-width: 769px) {
    .xmd-top-content-center {
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .xmd-bot-content-center {
        text-align: center;
    }
}

@media only screen and (min-width: 481px) {
    .sm-top-content-center {
        text-align: center;
    }
}

@media only screen and (max-width: 480px) {
    .sm-bot-content-center {
        text-align: center;
    }
}

.v-spacer {
    height: 52px;
    display: none;
    clear: both;
}

@media only screen and (min-width: 1367px) {
    .v-spacer.vs-xlg {
        display: block;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1366px) {
    .v-spacer.vs-lg {
        display: block;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .v-spacer.vs-md {
        display: block;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .v-spacer.vs-smd {
        display: block;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .v-spacer.vs-xmd {
        display: block;
    }
}

@media only screen and (min-width: 376px) and (max-width: 480px) {
    .v-spacer.vs-sm {
        display: block;
    }
}

@media only screen and (min-width: 361px) and (max-width: 375px) {
    .v-spacer.vs-xsm {
        display: block;
    }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
    .v-spacer.vs-sxsm {
        display: block;
    }
}

@media only screen and (min-width: 321px) and (max-width: 360px) {
    .v-spacer.vs-s {
        display: block;
    }
}

@media only screen and (max-width: 320px) {
    .v-spacer.vs-xs {
        display: block;
    }
}

@media only screen and (min-width: 769px) {
    .v-spacer.vs-xmd-top {
        display: block;
    }
}

@media only screen and (max-width: 768px) {
    .v-spacer.vs-xmd-bot {
        display: block;
    }
}

@media only screen and (min-width: 481px) {
    .v-spacer.vs-sm-top {
        display: block;
    }
}

@media only screen and (max-width: 480px) {
    .v-spacer.vs-sm-bot {
        display: block;
    }
}



/*
**====================================================================================
**			GRID KOLUMNOWY
**====================================================================================
**/
.skry-grid__row {

    .skry-grid__col {
        h5 { display: none; }
    }

    &--last {
        margin-bottom: -30px;
    }

    &--t-grid {
        display: grid;
        justify-content: center;

        &.skry-grid__row {
            &--cols-3 {
                grid-template-columns: 1fr 1fr 1fr;
                grid-column-gap: 20px;
                grid-row-gap: 30px;
            }
        }
    }
    &--t-flex {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: -20px;

        &.skry-grid__row {
            &--align-center {
                justify-content: center;
            }
            &--align-right {
                justify-content: flex-end;
            }
            .skry-grid__col {
                width: auto;

                /*&:nth-child(1) { background: red; }
                &:nth-child(2) { background: green; }
                &:nth-child(3) { background: silver; }
                &:nth-child(4) { background: blue; }
                &:nth-child(5) { background: pink; }
                &:nth-child(6) { background: orange; }*/

                &--1 { width: 5% };
                &--2 { width: 10% };
                &--3 { width: 15% };
                &--4 { width: 20% };
                &--5 { width: 25% };
                &--6 { width: 30% };
                &--7 { width: 35% };
                &--8 { width: 40% };
                &--9 { width: 45% };
                &--10 { width: 50% };
                &--11 { width: 55% };
                &--12 { width: 60% };
                &--13 { width: 65% };
                &--14 { width: 70% };
                &--15 { width: 75% };
                &--16 { width: 80% };
                &--17 { width: 85% };
                &--18 { width: 90% };
                &--19 { width: 95% };
                &--20 { width: 100% };
            }
            &--p-20 {
                margin: 0px -10px -20px;
                .skry-grid__col {
                    padding: 0px 10px;
                    margin-bottom: 20px;
                }
            }
            &--p-30 {
                margin: 0px -15px -30px;
                .skry-grid__col {
                    padding: 0px 15px;
                    margin-bottom: 30px;
                }
            }
        }
    }
    &--titles {
        .skry-grid__item h5 {
            display: block;
        }
    }
}

/*RWD*/
@media only screen and (min-width: 1200px) and (max-width: 1690px) {
    .skry-grid__row--t-flex.skry-grid__row--product-text .skry-grid__col-- {
        &5 {
            width: 40%;
        }
        &15 {
            width: 60%;
        }
    }
}
@media only screen and (max-width: 1199px) {
    .skry-grid__row--t-flex.skry-grid__row--product-text .skry-grid__col-- {
        &5 {
            width: 0%;
            display: none;
        }
        &15 {
            width: 100%;
        }
    }
}
@media only screen and (max-width: 1024px) {
    .skry-grid__row {
        &--t-flex {
            &.skry-grid__row {
                .skry-grid__col--smd {
                    &-1 { width: 5% };
                    &-2 { width: 10% };
                    &-3 { width: 15% };
                    &-4 { width: 20% };
                    &-5 { width: 25% };
                    &-6 { width: 30% };
                    &-7 { width: 35% };
                    &-8 { width: 40% };
                    &-9 { width: 45% };
                    &-10 { width: 50% };
                    &-11 { width: 55% };
                    &-12 { width: 60% };
                    &-13 { width: 65% };
                    &-14 { width: 70% };
                    &-15 { width: 75% };
                    &-16 { width: 80% };
                    &-17 { width: 85% };
                    &-18 { width: 90% };
                    &-19 { width: 95% };
                    &-20 { width: 100% };
                }
            }
        }
    }
}
@media only screen and (max-width: 800px) {
    .skry-grid__row {
        &--t-flex {
            &.skry-grid__row {
                .skry-grid__col--xmdl {
                    &-1 { width: 5% };
                    &-2 { width: 10% };
                    &-3 { width: 15% };
                    &-4 { width: 20% };
                    &-5 { width: 25% };
                    &-6 { width: 30% };
                    &-7 { width: 35% };
                    &-8 { width: 40% };
                    &-9 { width: 45% };
                    &-10 { width: 50% };
                    &-11 { width: 55% };
                    &-12 { width: 60% };
                    &-13 { width: 65% };
                    &-14 { width: 70% };
                    &-15 { width: 75% };
                    &-16 { width: 80% };
                    &-17 { width: 85% };
                    &-18 { width: 90% };
                    &-19 { width: 95% };
                    &-20 { width: 100% };
                }

                &--product-text {
                    .skry-grid__col--5 {
                        text-align: center;
                    }
                }
            }
        }
        &--t-grid {
            &.skry-grid__row {
                &--xmdl {
                    &-cols-2 {
                        grid-template-columns: 1fr 1fr;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    .skry-grid__row {
        &--t-flex {
            &.skry-grid__row {
                .skry-grid__col--xmd {
                    &-1 { width: 5% };
                    &-2 { width: 10% };
                    &-3 { width: 15% };
                    &-4 { width: 20% };
                    &-5 { width: 25% };
                    &-6 { width: 30% };
                    &-7 { width: 35% };
                    &-8 { width: 40% };
                    &-9 { width: 45% };
                    &-10 { width: 50% };
                    &-11 { width: 55% };
                    &-12 { width: 60% };
                    &-13 { width: 65% };
                    &-14 { width: 70% };
                    &-15 { width: 75% };
                    &-16 { width: 80% };
                    &-17 { width: 85% };
                    &-18 { width: 90% };
                    &-19 { width: 95% };
                    &-20 { width: 100% };
                }
            }
        }
        &--t-grid {
            &.skry-grid__row {
                &--xmd {
                    &-cols-2 {
                        grid-template-columns: 1fr 1fr;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 480px) {
    .skry-grid__row {
        &--t-flex {
            &.skry-grid__row {
                .skry-grid__col--sm {
                    &-1 { width: 5% };
                    &-2 { width: 10% };
                    &-3 { width: 15% };
                    &-4 { width: 20% };
                    &-5 { width: 25% };
                    &-6 { width: 30% };
                    &-7 { width: 35% };
                    &-8 { width: 40% };
                    &-9 { width: 45% };
                    &-10 { width: 50% };
                    &-11 { width: 55% };
                    &-12 { width: 60% };
                    &-13 { width: 65% };
                    &-14 { width: 70% };
                    &-15 { width: 75% };
                    &-16 { width: 80% };
                    &-17 { width: 85% };
                    &-18 { width: 90% };
                    &-19 { width: 95% };
                    &-20 { width: 100% };
                }
            }
        }
        &--t-grid {
            &.skry-grid__row {
                &--sm {
                    &-cols-1 {
                        grid-template-columns: 1fr;
                    }
                }
            }
        }
    }
}