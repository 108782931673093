@import "_settings.scss";
@import "_headers.scss";
@import "_content.scss";
@import "_blocks.scss";
@import "_404.scss";
@import "_cf7.scss";
@import "_search.scss";
@import "_tiles.scss";
@import "_ngg.scss";
@import "_blog.scss";
@import "_anim.scss";
@import "_bootcase.scss";

.main-navigation .menu > * {
	margin-left: 15px;
	margin-right: 15px;
}

body {
    &.tm_pb_builder {
        font-size: 20px;
    }

    &:not(.home) {
        .site-link__img {
            max-height: 85px;
            width: auto;
        }
    }

    .qc-box__text {
        font-family: Roboto;
        font-weight: 300;
    }

    &.error404 .site-content::before {
        display: none;
    }

    .ssmw__wrapper .ssmw__profile-content::before {
        z-index: -1;
    }
    .ssmw__wrapper--panelico .ssmw__panelico-btn-p1::before, .ssmw__wrapper--panelico .ssmw__panelico-btn-p1::after, .ssmw__wrapper--panelico .ssmw__panelico-btn-p2::before, .ssmw__wrapper--panelico .ssmw__panelico-btn-p2::after,
    .ssmw__wrapper--panelico .ssmw__panelico-lighter::before {
        background: #f19413;
    }

    .site {
        -moz-hyphens: none;
        hyphens: none;
    }

    &.use-tm-pb-builder.position-fullwidth .breadcrumbs + .site-content_wrap {
        margin-top: 0;
    }
    &.use-tm-pb-builder .site-main {
        margin-bottom: 0;
    }

    &.page-id-3727 {
        .footer-area-wrap {
            display: none;
        }
    }

    .breadcrumbs {
        background: #f9f9f9;
        border-top: 1px solid #e1e1e1;
    }

    .breadcrumbs__item-target {
        color: $color1;
    }
    .site-footer.style-2 .footer-container {
        padding-top: 55px;
        padding-bottom: 55px;
    }
    .site-footer.style-2 .site-info > * + .footer-copyright {
        margin: 55px 0 0;
    }

    .main-navigation .sub-menu > li > a:hover, .main-navigation .sub-menu > li.menu-hover > a, .main-navigation .sub-menu > li.current_page_item > a, .main-navigation .sub-menu > li.current_page_ancestor > a, .main-navigation .sub-menu > li.current-menu-item > a, .main-navigation .sub-menu > li.current-menu-ancestor > a {
        color: $color1;
    }
    .footer-area-wrap {
        background: url("../images/page-back-01.png") no-repeat center center / cover;
    }
    .footer-area .widget-title {
        color: #000;
    }

    .header-container {
        background-color: #f9f9f9;
        background-image: url("../images/page-back-02.png");
    }
    &:not(.home) {
        .invert .main-navigation > .menu > li > a {
            color: #000;
        }
        .invert .main-navigation .menu > li > a:hover, .invert .main-navigation .menu > li.menu-hover > a, .invert .main-navigation .menu > li.tm-mega-menu-hover > a, .invert .main-navigation .menu > li.current_page_item > a, .invert .main-navigation .menu > li.current_page_ancestor > a, .invert .main-navigation .menu > li.current-menu-item > a, .invert .main-navigation .menu > li.current-menu-ancestor > a {
            color: $color1;
        }
        .invert .main-navigation .menu > li > a::before {
            background: $color1;
        }
        .invert .btn, .invert .btn-primary, .invert .btn-default {
            color: $color1;
            box-shadow: 2px 3px 0 0 $color1;
        }
    }

    .jmmr__overlay .jmmr__c-info-wrapper .jmmr__c-info-button::before {
        border-bottom-color:$color1;
    }
    .jmmr__overlay .jmmr__c-info-wrapper .jmmr__c-info-button::after {
        background: $color1;
    }
}

div.fancybox-content {
	width: 100%;
	height: 100%;
}


.footer-area {
    padding: 55px 0 0px;

    .col-lg-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33%;
        -ms-flex: 0 0 33%;
        flex: 0 0 33%;
        max-width: 33%;
        margin-bottom: 30px;
    }
    .widget-title {
        text-align: center;
        font-size: 30px;
        margin-bottom: 0px;
    }
}

.isStuck {
    transform: translateY(-200px);
    -webkit-animation: 1s slideMenu;
            animation: 1s slideMenu;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes slideMenu {
    0% {
        transform: translateY(-200px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes slideMenu {
    0% {
        transform: translateY(-200px);
    }
    100% {
        transform: translateY(0px);
    }
}

#tm_builder_outer_content .tm_pb_module .logotype__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -30px;
    padding-top: 10px;

    .logotype__item {
        margin: 0px 15px 30px;
        display: flex;
        align-items: center;

        img {
            max-width: 200px;
        }

        span {
            color: #000;
            text-transform: uppercase;
        }

        &--logotyp-lubiana img { max-width: 130px; }
        &--logotyp-chodziez, &--logotyp-cmielow {
            img { max-width: 125px; }
        }
        &--logotyp-verlo {
            img { max-width: 135px; }
        }
        &--logotyp-robur, &--logotyp-peugeot {
            img { max-width: 95px; }
        }
        &--logotyp-amerbox {
            img { max-width: 240px; }
        }
        &--logotyp-rak {
            img { max-width: 115px; }
        }
    }
}
.home {
    #tm_builder_outer_content .tm_pb_module .logotype__wrapper .logotype__item {
        img {
            max-width: none;
            max-height: 45px;
        }

        &.logotype__item--logotyp- {
            &chodziez, &cmielow, &lubiana, &karolina,
            &bohemia, &bohemia-czech-republic, &aurum-crystal, &crystalite-bohemia, &simax, &rcr {
                img { max-height: 70px; }
            }
            &caterina {
                img { max-height: 25px; }
            }
            &amerbox {
                img { max-height: 30px; }
            }
            &bormioli-rocco, &libbey, &weck, &peugeot, &robur, &hisar, &victorinox, &rak {
                img { max-height: 80px; }
            }
            &rosetto {
                img { max-height: 65px; }
            }
        }

    }
}

.img-wrap {
    display: inline-block;
    position: relative;
    margin: 5px 5px 0px 0px;

    &::before {
        content: "";
        position: absolute;
        width: 50px;
        height: 50px;
        border-color: $color1;
        border-style: solid;
        border-width: 0px;
        z-index: -1;
    }

    &--left {
        &::before {
            bottom: -5px;
            left: -5px;
            border-bottom-width: 5px;
            border-left-width: 5px;
        }
    }
    &--right {
        &::before {
            top: -5px;
            right: -5px;
            border-top-width: 5px;
            border-right-width: 5px;
        }
    }
}

#tm_builder_outer_content .skry-content .tm_pb_module .images__wrapper {
    text-align: center;

    p {
        text-align: center;
    }

    .images__desc {
        font-weight: bold;
        color: #000;
        margin-top: 15px;
    }
}
.skry__p, p {
    & + .images__wrapper {
        margin-top: 25px;
    }
}

.main-navigation:not(.vertical-menu) .menu:not(.tm-mega-menu) .sub-menu {
	width: 1110px;
	display: flex;
    flex-wrap: wrap;
    padding: 26px 0 26px 25px;

    li {
        width: 33%;

        &::before {
            content: "";
            position: absolute;
            left: 0px;
            top: 8px;
            background: url("../images/chefsplace-list-icon.png") no-repeat center center/contain;
            width: 13px;
            height: 16px;
        }
    }
}
.main-navigation:not(.vertical-menu) .menu:not(.tm-mega-menu) > li > ul {
    left: -495px;
}
.main-navigation .sub-menu > li > a {
	width: auto;
	padding: 0px 20px 0px 20px;
	text-transform: none;
    font-size: 18px;
    display: inline-block;
}





@import "_rwd.scss";