/*
**====================================================================================
**			FORMULARZ KONTAKTOWY CF7
**====================================================================================
**/
.cfw {
    margin-top: 12px;

    .cfw__row {
        .cfw__col {
            width: 100%;
            margin: 0px 0px 10px 0px;
        }

        &.cfw__row--col2 .cfw__col {
            width: 48%;
        }

        &.cfw__row--col3 .cfw__col {
            width: 31%;
        }

        &[class*="cfw__row--col"] {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        &.cfw__row--aligncenter {
            text-align: center;
        }

        input:not(.skry-btn),
        textarea,
        select {
            background: #f7f7f7;
            padding: 13px 20px 13px 32px;
            border: 0px solid rgba(244, 244, 244, 0.8);
            box-shadow: 1px 0.7px 3px 0px rgba(0, 0, 0, 0.1);
            font-size: 19px;
            margin-top: 6px;
            box-sizing: border-box;
            width: 100%;
            text-transform: none;
            color: #4f4f56;

            &:focus {
                border: 0px solid rgb(236, 236, 238);
                background: #fff;
            }
        }

        input[type="text"],
        input[type="tel"],
        input[type="email"],
        select {
            height: 53px;

            &.wpcf7-not-valid {
                border: 1px solid transparent;
                background: #FFEEF1;
            }

            &.wpcf7-captchar {
                margin-bottom: 0px;
                display: inline-block;
                vertical-align: top;
                max-width: 115px;
            }
        }

        textarea {
            max-height: 200px;
        }

        .wpcf7-checkbox .wpcf7-list-item {
            position: relative;
            padding-left: 32px;
            margin-left: 0px;
            color: #000;

            input[type="checkbox"] {
                position: absolute;
                left: 0px;
                top: 0px;
                width: auto;
            }
        }

        h5 {
            color: black;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            text-align: left;
            line-height: normal;
            text-transform: none;
            letter-spacing: 0em;
            padding: 0px;
            margin: 0px;
            display: none;
        }

        span {
            &.wpcf7-form-control-wrap {
                height: auto;
                display: block;
                box-sizing: unset;

                &::before {
                    content: "";
                    font-family: 'FontAwesome';
                    font-size: 16px;
                    line-height: normal;
                    font-style: normal;
                    position: absolute;
                    top: 22px;
                    left: 11px;
                    color: $color1;
                }

                &::after {
                    content: "";
                    position: absolute;
                    right: -1px;
                    top: 5px;
                    background: transparent;
                    width: 0;
                    height: 0;
                    opacity: 0;
                    border-top: 15px solid $color1;
                    border-left: 15px solid transparent;
                    border-right: 0px solid transparent;
                    border-bottom: 15px solid transparent;
                    transition: all 0.3s ease;
                }

                &.wfcw--focus {
                    &::after {
                        opacity: 1;
                    }
                }
            }

            &.your-name::before {
                content: "\f007";
            }

            &.your-email::before {
                content: "\f0e0";
            }

            &.your-phone::before {
                content: "\f10b";
                font-size: 22px;
                top: 19px;
            }

            &.your-message::before {
                content: "\f040";
            }

            &.captcha-form::before {
                content: "\f023";
            }

            &.wpcf7-not-valid-tip {
                font-size: 11px;
                line-height: 16px;
                left: 0;
                top: 0;
                position: relative;
                text-align: center;
                right: 0px;
                font-style: normal;
                font-weight: bold;
                color: rgb(254, 92, 122);
                margin-top: 3px;
                text-transform: none;
            }
        }

        &.cfw__row--file {
            margin-bottom: 15px;

            h5 {
                display: block;
            }

            .cfw__file-fieldwrap {
                display: inline-block;
                width: auto;
                position: relative;
                margin-right: 5px;
                border: 0px solid rgba(244, 244, 244, 0.8);
                box-shadow: 1px 0.7px 3px 0px rgba(0, 0, 0, 0.1);
                background: #f7f7f7;
                padding: 13px 20px 13px 32px;
                border: 0;
                font-size: 16px;
                margin-top: 6px;
                box-sizing: border-box;
                text-transform: none;
                height: 57px;

                &::before {
                    content: "\f15b";
                    font-family: 'FontAwesome';
                    font-size: 16px;
                    line-height: normal;
                    font-style: normal;
                    position: absolute;
                    top: 18px;
                    left: 11px;
                    color: $color1;
                }
            }

            .cfw__file-btn {
                position: relative;
                top: 1px;
                right: auto;
                bottom: auto;
                left: 0;
                background: $color1;
                padding: 3px 20px;
                margin-right: 3px;
                font-size: 14px;
                cursor: pointer;
                color: #fff;
                font-weight: 500;
                z-index: 1;
                border-radius: 2px;
                transition: all 0.3s ease;

                &:hover,
                &:focus {
                    background: #303941;
                }
            }

            .cfw__file-name {
                position: relative;
                top: 1px;
            }

            .cfw__file-field {
                display: none;
            }

            p i {
                font-size: 14px;
                position: relative;
                top: 0px;
                left: 0px;
            }

            span.wpcf7-form-control-wrap::after {
                display: none;
            }
        }

        &.cfw__row--captcha {
            .cfw__col {
                box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
                padding: 20px;
                display: inline-block;
                width: auto;
                background: #fff;
            }

            .cfw__wrapfield-captcha {
                display: flex;
                align-items: flex-start;

                .wpcf7-captchac {
                    position: relative;
                    top: 14px;
                }

                .wpcf7-captchar {
                    box-shadow: none;
                    background: transparent;
                    border-bottom: 1px solid #eaeaea;
                    height: 45px;
                }

                .wpcf7-form-control-wrap {
                    margin-left: 10px;

                    &::before {
                        top: 17px;
                    }
                }

                .wpcf7-not-valid-tip {
                    position: relative;
                    left: -88px;
                }
            }

            h5 {
                display: block;
                padding-bottom: 5px;
            }
        }

        &.cfw__row--rodo {
            margin-bottom: 15px;
            margin-top: 15px;

            .wpcf7-list-item {
                line-height: 19px;
            }

            .wpcf7-list-item-label {
                font-size: 15px;
                line-height: normal;
            }

            span.wpcf7-not-valid-tip {
                text-align: left;
            }
        }

        &.cfw__row--submit {
            position: relative;
            margin-top: 20px;

            .ajax-loader {
                display: inline-block;
                margin: 0 0 0 15px;
                background-color: white;
                border-radius: 25px;
                vertical-align: middle;
                background-image: url('../images/cf-loader.gif');
                width: 36px;
                height: 36px;
            }

            .wpcf7-submit {
                display: none;
            }
        }
    }

    ::-webkit-input-placeholder {
        color: #8C8C8C;
    }

    ::-ms-input-placeholder {
        color: #8C8C8C;
    }

    :placeholder-shown {
        color: #8C8C8C;
    }

    ::-webkit-input-placeholder {
        color: #8C8C8C;
    }

    ::-moz-placeholder {
        color: #8C8C8C;
    }

    :-ms-input-placeholder {
        color: #8C8C8C;
    }

    ::-ms-input-placeholder {
        color: #8C8C8C;
    }

    ::placeholder {
        color: #8C8C8C;
    }
}

.wpcf7 {
    .wpcf7-response-output {
        margin: 15px 0 !important;
    }

    .wpcf7-validation-errors,
    .wpcf7-mail-sent-ng,
    .wpcf7-mail-sent-ok {
        margin: 1.5em 0;
        padding: 14px 15px;
        border: none;
        font-size: 16px;
        border-radius: 3px;
        font-style: normal;
        color: #000000;
    }

    .wpcf7-validation-errors {
        background: rgb(227, 126, 126) !important;
    }

    .wpcf7-form {
        .wpcf7-response-output {
            line-height: normal;
            border: 0px solid transparent !important;
            color: rgb(255, 255, 255) !important;
            box-shadow: 1px 0.7px 3px 0px rgba(0, 0, 0, 0.1);
            text-shadow: none !important;
            margin: 1.5em 0;
            padding: 14px 15px;
            border: none;
            font-size: 16px;
            border-radius: 3px;
            font-style: normal;
            color: #000000;
        }
        &.invalid {
            .wpcf7-response-output {
                background: rgb(227, 126, 126);
            }
        }
        &.sent {
            .wpcf7-response-output {
                background: rgb(95, 204, 50)
            }
        }
    }

    .wpcf7-response-output {
        line-height: normal;
        border: 0px solid transparent !important;
        color: rgb(255, 255, 255) !important;
        box-shadow: 1px 0.7px 3px 0px rgba(0, 0, 0, 0.1);
        text-shadow: none !important;

        &.wpcf7-mail-sent-ng {
            background: rgb(234, 156, 54) !important;
        }

        &.wpcf7-mail-sent-ok {
            background: rgb(95, 204, 50) !important;
        }
    }
}

.mrf-mod {
    text-align: left;

    input[type="checkbox"] {
        display: none;
    }

    .mrf-mod__checkbox {
        width: 20px;
        height: 20px;
        background: #f7f7f7;
        border: 0px solid rgba(244, 244, 244, 0.8);
        box-shadow: 1px 0.7px 3px 0px rgba(0, 0, 0, 0.1);
        display: block;
        position: absolute;
        left: 0px;
        top: 7px;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            background: #eeeeee;
        }

        &::before {
            content: "\f00c";
            font-family: 'FontAwesome';
            font-size: 16px;
            line-height: normal;
            font-style: normal;
            position: absolute;
            top: 0;
            left: 3px;
            color: $color1;
            opacity: 0;
            transition: all 0.3s ease;
        }

        &.mrf-s-check::before {
            opacity: 1;
        }
    }
}

@media (max-width: 1200px) {
    .cf-row[class*="cf-row-col"] .cf-col {
        width: 100%;
    }
}

@media (max-width: 768px) {

    .cf-row.cf-row-captcha,
    .cf-row.cf-row-submit {
        text-align: center;
    }

    .cf-row .cf-wrap-field-submit {
        margin-left: 51px;
    }

    body div.wpcf7-response-output {
        text-align: center;
    }
    .cfw .cfw__row.cfw__row--col3 .cfw__col {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {

    .cfw .cfw__row.cfw__row--captcha,
    .cfw .cfw__row.cfw__row--submit .cfw__col {
        text-align: center;
    }

    .cfw .cfw__row.cfw__row--submit .ajax-loader {
        display: block;
        margin: 0px;
        border-radius: 0x;
        width: 100%;
        height: auto;
        position: absolute;
        right: 0px;
        top: 0px;
        left: 0px;
        bottom: -5px;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 0px;
    }
}

@media only screen and (max-width: 375px) {
    .cfw {
        .cfw__row {
            &.cfw__row--submit {
                .cf-wrapfield-submit {
                    margin-bottom: -5px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .skry-btn {
                        margin-bottom: 5px;

                        &+.skry-btn {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 360px) {
    .cfw .cfw__row {
        .cfw__col {
            margin-bottom: 25px;
        }

        h5 {
            font-weight: 500;
            font-size: 14px;
            display: block;
        }
    }
}

@media only screen and (max-width: 320px) {
    .cf-wrapfield-submit .skry-btn {
        padding: 8px 10px;
    }
}