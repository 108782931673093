/*
**====================================================================================
**			TEKSTY, LISTA, ZDJĘCIA
**====================================================================================
**/
#tm_builder_outer_content .skry-content .tm_pb_module {

    p,
    .skry-list li {
        font-family: Roboto;
        text-align: left;
        color: #4f4f4f;
        font-weight: 300;
    }

    p {
        margin-bottom: 15px;

        &.text-center {
            text-align: center;
        }

        &.text-left {
            text-align: left;
        }

        &.text-right {
            text-align: right;
        }

        &:last-of-type {
            margin-bottom: 0px;
        }

        &:last-of-type+ul {
            padding-top: 1px;
        }

        &:last-of-type+.skry-list {
            margin-top: 0px;
        }

        &:last-of-type+.skry-btn,
        &:last-of-type+.skry-btn-wrapper {
            margin-top: 25px;
        }
    }

    .skry-list+p,
    .skry-btn+p,
    .skry-btn-wrapper+p {
        margin-top: 25px;
    }

    p+.skry-list {
        margin-top: -15px;
    }

    .skry-list {
        list-style-type: none;
        padding: 0px;
        margin: 0px 0 20px;

        &:last-of-type {
            margin-bottom: 0px;
        }

        li {
            position: relative;
            padding-left: 21px;

            &::before {
                content: "";
                position: absolute;
                top: 12px;
                bottom: 0px;
                left: 0px;
                background: transparent;
                width: 10px;
                height: 10px;
                background: $color1;
                border-radius: 25px;
            }

            li {
                &:before {
                    /*background: #ef7f1a;*/
                }
            }
        }
    }
}

#tm_builder_outer_content {
    .tm_pb_module img {
        &.aligncenter {
            clear: both;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
        }

        &.alignleft {
            float: left;
            margin: 15px 30px 15px 0;
        }

        &.alignright {
            float: right;
            margin: 15px 0 15px 30px;
        }
    }
    .skry-content--banner {
        .tm_pb_module img {
            margin-bottom: 0px;
        }
    }
}
#tm_builder_outer_content .tm_pb_module .skry-grid__row--product-text {
    img {
        border: 1px solid #eaeaea;
    }
}

/* Teksty, lista, zdjęcia RWD */
@media only screen and (max-width: 1366px) {
    #tm_builder_outer_content .skry-content .tm_pb_module .skry-list li::before {
        top: 11px;
    }
}
@media only screen and (max-width: 800px) {
    .skry-content {
        p, .skry-list li {
            line-height: 25px;
            font-size: 17px;
        }
    }
    #tm_builder_outer_content .skry-content .tm_pb_module .skry-list li::before {
        top: 9px;
        width: 7px;
        height: 7px;
    }
    #tm_builder_outer_content .tm_pb_module .skry-grid__row--product-text img {
        max-height: 220px;
        width: auto;
    }
}
@media only screen and (max-width: 768px) {
    .skry-content {
        p {
            margin: 0px 0px 15px 0px;
        }

        p+.skry-list {
            margin-top: -15px;
        }
    }


    #tm_builder_outer_content .tm_pb_module img {
        &.aligncenter--xmdbot {
            clear: both;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
        }
    }
}
@media only screen and (max-width: 767px) {
    #tm_builder_outer_content .tm_pb_module .skry-grid__row--product-text img {
        max-height: 180px;
    }
}

@media only screen and (max-width: 480px) {
    .skry-content {

        p,
        .skry-list li {
            font-size: 16px;
            line-height: 24px;
        }

        .skry-list {
            li {
                &::before {
                    top: 9px;
                    width: 7px;
                    height: 7px;
                }
            }
        }
    }
}



/*
**====================================================================================
**			PRZYCISK
**====================================================================================
**/
.skry-btn {
    color: #000000;
    box-shadow: 2px 3px 0 0 #000000;
    border: 1px solid #000;
    padding: 13px 38px;
    font-size: 16px;
    line-height: normal;
    text-transform: uppercase;
    width: auto;
    font-weight: 400;
    cursor: pointer;
    border-radius: 0px;
    display: inline-block;
    transition: all 0.3s ease;

    &.skry-btn--invert {
        background: #fff;
        color: $color1;
    }

    &.skry-btn--icon {
        padding-left: 40px;
        position: relative;
    }

    &:hover,
    &:focus {
        background-color: #000;
        color: #fff;
        text-decoration: none;
        box-shadow: none;
        transform: translateX(2px) translateY(3px) translateZ(0);
    }

    .fa::before {
        font-size: 18px;
        margin-right: 10px;
        position: absolute;
        top: 13px;
        left: 15px;
    }

    .fa.fa-wpforms::before {
        font-size: 20px;
        top: 15px;
        left: 31px;
    }

    .fa.fa-envelope::before {
        font-size: 19px;
    }

    &+.skry-btn {
        margin-left: 5px;
    }
}

.skry-btn-wrapper {
    &.skry-btn-wrapper--center {
        text-align: center;
    }

    &.skry-btn-wrapper--right {
        text-align: right;
    }

    &.skry-btn-wrapper--col {
        .skry-btn {
            margin-left: 0px !important;
        }

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &.skry-btn-wrapper--center {
            align-items: center;
        }

        &.skry-btn-wrapper--right {
            align-items: flex-end;
        }
    }
}
.logotype__wrapper + .skry-btn-wrapper {
    margin-top: 50px;
}

@media only screen and (max-width: 768px) {
    .skry-btn-wrapper {
        &.skry-btn-wrapper--center-xmdbot {
            text-align: center;
        }

        &.skry-btn-wrapper--right-xmdbot {
            text-align: right;
        }

        &.skry-btn-wrapper--col-xmdbot {
            .skry-btn {
                margin-left: 0px !important;
            }

            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &.skry-btn-wrapper--center-xmdbot,
            &.skry-btn-wrapper--center {
                align-items: center;
            }

            &.skry-btn-wrapper--right-xmdbot {
                align-items: flex-end;
            }
        }
    }

    .skry-btn {
        font-size: 11px;
        padding: 15px 38px 13px;

        .fa::before {
            top: 11px;
        }
    }
}

@media only screen and (max-width: 480px) {
    .skry-btn-wrapper {
        &.skry-btn-wrapper--center-smbot {
            text-align: center;
        }

        &.skry-btn-wrapper--right-smbot {
            text-align: right;
        }

        &.skry-btn-wrapper--col-smbot {
            .skry-btn {
                margin-left: 0px !important;
            }

            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &.skry-btn-wrapper--center-smbot,
            &.skry-btn-wrapper--center {
                align-items: center;
            }

            &.skry-btn-wrapper--right-smbot {
                align-items: flex-end;
            }
        }
    }

    .skry-btn {
        &.skry-btn--icon.skry-btn--wide {
            padding: 11px 20px 11px 20px;
        }

        &.skry-btn--wide .fa::before {
            margin: 0px auto;
            position: relative;
            top: 0;
            left: 0;
            display: block;
        }
    }
}

@media only screen and (max-width: 320px) {
    .skry-btn {
        font-size: 10px;
        padding: 8px 21px;

        .fa::before {
            top: 6px;
        }
    }
}


/*
**====================================================================================
**			KONTAKT BOX LISTA
**====================================================================================
**/
@mixin skryCbCenter() {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .skry-cb__icon {
        margin-right: 0px;
    }

    .skry-cb__item {
        justify-content: center;
        flex-direction: column;

        &--top {
            align-items: center;
        }

        .skry-cb__data {
            text-align: center;

            i {
                font-size: 13px;
            }
        }
    }
}

.skry-cb__wrapper {

    & > br { display: none; }

    & + .skry__p.skry__p {
        margin-top: 30px;
    }

    .skry-cb__icon {
        margin-right: 10px;
        width: 20px;
        text-align: center;

        .fa {
            color: $color1;
            font-size: 22px;
            position: relative;

            &.fa-envelope {
                font-size: 20px;
            }
            &.fa-phone {
                font-size: 20px;
                top: 2px;
            }
        }
    }
    .skry-cb__item {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 25px;

        &:last-child, &--nmb { margin-bottom: 0px; }

        &--center {
            align-items: center;
        }
        &--top {
            align-items: flex-start;
        }
        &--bottom {
            align-items: flex-end;
        }

        a {
            color: #42474c;
            transition: color 0.3s ease;

            &:hover, &:focus {
                color: $color1;
            }
        }

        &--title {
            margin-bottom: 5px;
            .skry-cb__icon {
                display: none;
            }
            .skry-cb__data {
                font-weight: bold;
            }
        }

        &.skry-cb__item--info {
            .skry-cb__data i {
                font-size: 16px;
            }
            .skry-cb__icon {
                display: none;
            }
        }
    }

    &--center {
        @include skryCbCenter;
    }
    &--footer {
        .skry-cb__icon {
            height: 33px;
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            .fa {
                color: #fff;
            }

            &.skry-cb__icon-no {
                display: none;
            }
        }
        .skry-cb__item {
            color: #fff;

            a {
                color: #fff;

                &:hover, &:focus {
                    color: #000;
                }
            }
        }
    }
    &--mobile {
        @include skryCbCenter;
        .skry-cb__item {
            margin-bottom: 9px;

            &--nmb { margin-bottom: 0px; }

            .skry-cb__data {
                font-size: 16px;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .skry-cb__wrapper {
        &--footer {
            .skry-cb__item .skry-cb__data {
                font-size: 18px;
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    .skry-cb__wrapper {
        @include skryCbCenter;
    }
}