/*******/
/* RWD */
/*******/

/* Tablety - opcjonalnie */
@media only screen and (max-width: 1366px) {
    .site-header.default .site-branding {
        max-width: 155px;
    }
    .site-header.default .header-btn {
        max-width: 200px;
        white-space: normal;
        line-height: 17px;
        margin-right: 80px;
    }
}

@media only screen and (max-width: 1248px) {
    .main-navigation .menu > * {
        margin-left: 12px;
        margin-right: 12px;
    }
    .main-navigation .menu > li > a {
        font-size: 14px;
    }
}

/* Monitory (desktop + laptop) */
@media only screen and (max-width: 1200px) {

}
@media only screen and (min-width: 801px) and (max-width: 1366px) {
    body.tm_pb_builder {
        font-size: 19px;
    }
}
@media only screen and (max-width: 1199px) {
    .site-header.default .header-btn {
        margin-right: 0;
    }
    header .nav.nav__primary,.mobile-panel,.header-nav-wrapper,.top-panel,.main-navigation {
        display: none;
    }
}

/* Tablety, netbooki */
@media only screen and (max-width: 1024px) {

}
@media only screen and (max-width: 991px) {
    .footer-area-wrap .container {
        max-width: none;
    }
    .site-header {
        margin-top: 0;
    }
    .btn {
        padding-top: 9px;
        padding-bottom: 9px;
        font-size: 15px;
    }
    .home .site-link__img {
        max-height: 80px;
        width: auto;
    }
}
@media only screen and (max-width: 800px) {
    body.tm_pb_builder {
        font-size: 17px;
        line-height: 25px;
    }
    .img-wrap {

        &--right {
            &::before {
                bottom: -5px;
                left: -5px;
                top: auto;
                right: auto;
                border-bottom-width: 5px;
                border-left-width: 5px;
                border-top-width: 0px;
                border-right-width: 0px;
            }
        }
        &--left {
            &::before {
                top: -5px;
                right: -5px;
                bottom: auto;
                left: auto;
                border-top-width: 5px;
                border-right-width: 5px;
                border-bottom-width: 0px;
                border-left-width: 0px;
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    body .qc-box__text {
        text-align: center;
    }
}
@media only screen and (max-width: 685px) {
    .footer-area {
        padding: 55px 0 20px;
    }
    .footer-area .col-lg-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px;
    }
}
@media only screen and (max-width: 480px), only screen and (max-width: 800px) and (orientation: landscape) {

}

/* Landscape */
@media only screen and (max-width: 800px) and (orientation: landscape) {

}

/* Telefony, smartfony - przedział wysokości 640 – 960 px ~ bazowo 800px, szerokość - warto przyjrzeć się 375px*/
@media only screen and (max-width: 480px) {
    .images__wrapper .images__desc {
        font-size: 18px;
    }
    .footer-area .widget-title {
        font-size: 25px;
    }
}
@media only screen and (max-width: 375px) {

}
@media only screen and (max-width: 360px) {

}
@media only screen and (max-width: 320px) {

}